.bounding-box {
	position: absolute;
	box-shadow: 0 0 0 3px #149df2 inset;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	-flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-self: center;
	cursor: pointer;
}